import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { CreateAlbumRequest, FoundInsertAlbums } from "../../store/types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Snackbar
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import produce from "immer";
import { Api } from "../../utils/apiClient";
import { createBuyLink, NewAlbumForm } from "./NewAlbumForm";
import { useQuery } from "../../utils/hooks";
import { AppContext } from "../../store/context";
import { AppRoutes } from "../../utils/routes";
import { useHistory } from "react-router-dom";

type StateProps = {};

type DispatchProps = {};

type Props = StateProps & DispatchProps;

// const arrayNotEmpty = (array: any): boolean => {
//   return Array.isArray(array) && array.length > 0;
// };

const initialState: CreateAlbumRequest = {
  artist: "",
  album: "",
  label: "",
  genre: "",
  artwork: "",
  single: false,
  streamingServices: {
    appleMusic: "",
    spotify: "",
    tidal: "",
    amazonMusic: "",
    youtube: "",
    deezer: ""
  },
  buy: {
    title: "",
    link: ""
  },
  releaseDate: new Date(),
  addedAt: new Date()
};

function retrieveLink(links: any, service: string) {
  // console.log("retrieveLink", links);
  const link = links.find((link: any) => link.platform === service);
  // console.log("retrieveLink link", link);
  return link?.url;
}

const foundAlbumsInitial = {
  data: {
    albums: []
  }
};

const InsertAlbum: FunctionComponent<Props> = () => {
  const query = useQuery();
  const history = useHistory();

  const { selectedCrawledAlbum, setSelectedCrawledAlbum } = useContext(
    AppContext
  );

  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [foundAlbums, setFoundAlbums] = useState<FoundInsertAlbums>(
    foundAlbumsInitial
  );

  const [newRelease, setNewRelease] = useState<CreateAlbumRequest>(
    initialState
  );

  const [albumForFill, setAlbumForFill] = useState<any>({});

  const [error, setError] = useState();

  const [searchTerm, setSearchTerm] = useState(query.get("searchTerm") || "");
  const [searchedAtLeastOnce, setSearchedAtLeastOnce] = useState(false);
  const [searchResultExpanded, setSearchResultExpanded] = useState(false);
  // State and setter for search results
  // State for search status (whether there is a pending API request)

  const [addIgnores, setAddIgnores] = useState(true);

  function setInitialState() {
    setNewRelease(initialState);
    setSearchTerm("");
    setFoundAlbums(foundAlbumsInitial);
  }

  function submitAlbumSearch() {
    if (searchTerm) {
      if (searchTerm.startsWith("http")) {
        let res = searchTerm.match(/([0-9]+)/g);
        console.log(res);
        if (res && res.length > 0) {
          triggerAlbumFill(res![0]);
        } else {
          setError("Could not parse collection ID from URL");
        }
      } else {
        setFoundAlbums(foundAlbumsInitial);
        setIsSearching(true);
        Api.albumInsertSearch(searchTerm)
          .then(value => {
            setFoundAlbums(value);
          })
          .finally(() => {
            setIsSearching(false);
            setSearchedAtLeastOnce(true);
            setSearchResultExpanded(true);
          });
      }
    }
  }

  useEffect(() => {
    // console.log("Album for fill", albumForFill);
    if (
      albumForFill &&
      albumForFill.props &&
      albumForFill.props.pageProps &&
      albumForFill.props.pageProps.pageData
    ) {
      const fillData = albumForFill.props.pageProps.pageData;

      // console.log("Fill data", fillData);

      setNewRelease(
        produce(initialState, (draft: CreateAlbumRequest) => {
          const entityData = fillData.entityData;
          draft.artwork = entityData.thumbnailUrl;
          draft.album =
            entityData.title
              ?.replace(" - Single", "")
              ?.replace("- EP", "")
              ?.trim() || "";
          draft.artist = entityData.artistName;
          draft.single = entityData.numTracks === 1;
          try {
            const releaseDate = entityData.releaseDate;
            draft.releaseDate = new Date(
              releaseDate.year,
              releaseDate.month - 1,
              releaseDate.day,
              releaseDate.hour,
              releaseDate.minute
            );
          } catch (e) {
            console.error("Could not format release date", e);
          }

          const listenSections = fillData.sections.find(
            // @ts-ignore
            section => section.sectionId === "section|auto|links|listen"
          );
          if (listenSections) {
            const links = listenSections.links;
            const appleMusicLink = retrieveLink(links, "appleMusic");
            if (appleMusicLink) {
              draft.streamingServices.appleMusic = appleMusicLink.replace(
                "{country}",
                "us"
              );
            }
            draft.streamingServices.spotify = retrieveLink(links, "spotify");
            draft.streamingServices.tidal = retrieveLink(links, "tidal");
            draft.streamingServices.deezer = retrieveLink(links, "deezer");
            draft.streamingServices.youtube =
              retrieveLink(links, "youtubeMusic") ||
              retrieveLink(links, "youtube");
            draft.streamingServices.youtubeMusic = retrieveLink(
              links,
              "youtubeMusic"
            );

            const bandcampLink = retrieveLink(links, "bandcamp");
            if (bandcampLink) {
              draft.buy.title = "Bandcamp";
              draft.buy.link = bandcampLink;
            }
          }

          if (selectedCrawledAlbum) {
            draft.label = selectedCrawledAlbum.label;
            draft.genre = selectedCrawledAlbum.genre;
            // draft.single = selectedCrawledAlbum.single;

            if (!draft.buy || !draft.buy.link) {
              draft.buy = createBuyLink(selectedCrawledAlbum.url);
            }
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumForFill]);

  const triggerAlbumFill = (collectionId: string) => {
    setIsLoading(true);
    Api.triggerAlbumFill(collectionId)
      .then(value => {
        setAlbumForFill(value);
        setSearchResultExpanded(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAlbumComplete = () => {
    if (selectedCrawledAlbum && selectedCrawledAlbum.pendingAlbumId) {
      Api.deletePendingAlbums(selectedCrawledAlbum.pendingAlbumId).then(() => {
        setSelectedCrawledAlbum(undefined);
        history.push(`${AppRoutes.PENDING}`);
      });
    } else {
      setInitialState();
    }
  };

  const addWaitingRelease = () => {
    Api.addWaitingRelease(
      searchTerm,
      addIgnores ? foundAlbums : { data: undefined },
      selectedCrawledAlbum
    )
      .then(() => {
        handleAlbumComplete();
      })
      .catch(reason => {
        console.log("Error occurred", reason);
        alert("Error occurred submitting waiting release");
      });
  };

  return (
    <Container component="main" maxWidth="md">
      <Snackbar
        open={!!error}
        autoHideDuration={20000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setError("");
        }}
      >
        <Alert
          onClose={() => {
            setError("");
          }}
          severity="error"
        >
          {error}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          marginTop: "theme.spacing(8)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography component="h1" variant="h5">
          Add Album
        </Typography>

        <Box mb={3} width="100%">
          <TextField
            multiline
            variant="outlined"
            margin="normal"
            fullWidth
            id="search"
            label="Search - powered by Songwhip"
            name="search"
            autoFocus
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
            onKeyPress={ev => {
              if (ev.key === "Enter") {
                submitAlbumSearch();
                ev.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    style={{ marginRight: 4 }}
                    aria-label="search"
                    onClick={submitAlbumSearch}
                    size="large"
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton
                    aria-label="add to waiting"
                    onClick={addWaitingRelease}
                    size="large"
                  >
                    <ScheduleIcon />
                  </IconButton>
                  <Checkbox
                    checked={addIgnores}
                    onChange={(event, checked) => {
                      setAddIgnores(checked);
                    }}
                    color="primary"
                    name="Add Ignores"
                  />
                </>
              )
            }}
          />

          {selectedCrawledAlbum && (
            <Box my={2}>
              Selected Crawled Album:{" "}
              <a
                href={selectedCrawledAlbum.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            </Box>
          )}

          {searchTerm &&
            searchedAtLeastOnce &&
            !isSearching &&
            (!foundAlbums.data ||
              !foundAlbums.data.albums ||
              foundAlbums.data.albums.length === 0) && (
              <Box my={2}>Could not find any albums for prefill</Box>
            )}

          {isSearching && <Box my={2}>Searching ...</Box>}

          <Accordion
            expanded={searchResultExpanded}
            onChange={(event, expanded) => {
              setSearchResultExpanded(expanded);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="expand results list"
            >
              <Typography>Search Results</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction={"column"}>
                {foundAlbums.data &&
                  foundAlbums.data.albums &&
                  foundAlbums.data.albums.map(value => {
                    return (
                      <Box
                        key={value.sourceUrl}
                        mb={1}
                        p={1}
                        sx={{
                          backgroundColor: "#3f3f3f",
                          border: "1px solid #fff"
                          // cursor: "pointer"
                        }}
                      >
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          paddingX={"5px"}
                        >
                          <Grid item>
                            <Grid container direction={"column"}>
                              <Grid item>
                                {value.artists
                                  .map(artist => artist.name)
                                  .join(", ")}
                                {" - "}
                                {value.name}
                              </Grid>
                              <Grid item>
                                <Button
                                  href={value.sourceUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Check link
                                </Button>
                                <Box component={"span"} ml={2}>
                                  <Button
                                    disabled={isLoading}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      triggerAlbumFill(value.collectionId!!);
                                    }}
                                  >
                                    Load
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <IconButton
                              disabled={isLoading}
                              size="small"
                              onClick={() => {
                                setFoundAlbums(prevState => {
                                  return {
                                    data: {
                                      albums: prevState.data!!.albums.filter(
                                        foundAlbum =>
                                          foundAlbum.sourceUrl !==
                                          value.sourceUrl
                                      )
                                    }
                                  };
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <NewAlbumForm
          prefilledRelease={newRelease}
          crawledAlbum={selectedCrawledAlbum}
          onAlbumSubmit={handleAlbumComplete}
        />
      </Box>
    </Container>
  );
};

export { InsertAlbum };
