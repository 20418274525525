import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { Api } from "../utils/apiClient";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";
import { PendingAlbum } from "../store/types";
import TextField from "@mui/material/TextField";
import { NewAlbumForm } from "./insertAlbum/NewAlbumForm";
import { formatDateShort, isOlderThanMonth } from "../utils/date";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../utils/routes";
import { AppContext } from "../store/context";
import { sub } from "date-fns";
import { useIsMobile } from "../utils/hooks";

type Props = {};

const Pending: FunctionComponent<Props> = () => {
  const history = useHistory();
  const {
    setSelectedCrawledAlbum,
    onlyFreshPending,
    setOnlyFreshPending
  } = useContext(AppContext);

  const isMobile = useIsMobile();

  const [pendingAlbums, setPendingAlbums] = useState<PendingAlbum[]>([]);
  const [selectedPendingAlbum, setSelectedPrefilledAlbum] = useState<
    PendingAlbum
  >();
  const [filterLabel, setFilterLabel] = useState();
  const [filterGenre, setFilterGenre] = useState();
  const [filterPrefilled, setFilterPrefilled] = useState(false);
  const [hideSingles, setHideSingles] = useState(false);

  const getFilteredPendingAlbums = () => {
    let result = pendingAlbums;
    if (filterLabel) {
      result = pendingAlbums.filter(value => {
        return (
          value.prefilledAlbum && value.prefilledAlbum.label === filterLabel
        );
      });
    }

    if (filterGenre) {
      result = result.filter(value => {
        return value.crawledAlbum && value.crawledAlbum.genre === filterGenre;
      });
    }

    if (filterPrefilled) {
      result = result.filter(value => {
        return !!value.prefilledAlbum;
      });
    }

    if (hideSingles) {
      result = result.filter(value => {
        return (
          !(value.prefilledAlbum && value.prefilledAlbum.single) &&
          !(value.crawledAlbum && value.crawledAlbum.single)
        );
      });
    }

    if (onlyFreshPending) {
      result = result.filter(value => {
        const releaseDateValue = findReleaseDate(value);
        if (releaseDateValue) {
          return !isOlderThanMonth(releaseDateValue);
        } else {
          return true;
        }
      });
    }

    return result.sort((a, b) => (a.addedAt < b.addedAt ? 1 : -1));
  };

  const getDistinctLabels = (): string[] => {
    if (pendingAlbums && pendingAlbums.length > 0) {
      return (
        pendingAlbums
          .map(value =>
            value.prefilledAlbum ? value.prefilledAlbum.label || "" : ""
          )
          .filter((elem, pos, arr) => {
            return arr.indexOf(elem) === pos;
          }) || []
      );
    }
    return [];
  };

  const getGenres = (): string[] => {
    if (pendingAlbums && pendingAlbums.length > 0) {
      return (
        pendingAlbums
          .map(value => {
            return value.crawledAlbum ? value.crawledAlbum.genre || "" : "";
          })
          .filter((elem, pos, arr) => {
            return arr.indexOf(elem) === pos;
          })
          .sort((a, b) => a.localeCompare(b)) || []
      );
    }
    return [];
  };

  function fetchPendingAlbums() {
    Api.retrievePendingAlbums().then(response => {
      setPendingAlbums(response);
    });
  }

  useEffect(() => {
    const cancelTokenSource = Api.cancelToken();
    fetchPendingAlbums();
    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const deletePendingAlbumNow = (
    id: string,
    onDeleted: (() => void) | undefined
  ) => {
    Api.deletePendingAlbums(id).then(() => {
      fetchPendingAlbums();
      if (onDeleted) {
        onDeleted();
      }
    });
  };

  const deletePendingAlbum = (id?: string, onDeleted?: () => void) => {
    const confirmed = window.confirm("Delete?");
    if (confirmed && id) {
      deletePendingAlbumNow(id, onDeleted);
    }
  };

  const handleClose = () => {
    setSelectedPrefilledAlbum(undefined);
  };

  const findReleaseDate = (pendingAlbum: PendingAlbum) => {
    if (pendingAlbum.prefilledAlbum) {
      return (pendingAlbum.prefilledAlbum.releaseDate as unknown) as string;
    } else if (pendingAlbum.crawledAlbum) {
      return pendingAlbum.crawledAlbum.datePublished;
    }
    return null;
  };

  const findReleaseDateForSort = (pendingAlbum: PendingAlbum) => {
    let releaseDate = findReleaseDate(pendingAlbum);
    if (!releaseDate) {
      return sub(new Date(), { days: 20 }).getTime();
    } else {
      return new Date(releaseDate).getTime();
    }
  };

  const retrieveReleaseDate = (row: PendingAlbum): String => {
    const releaseDate = findReleaseDate(row);
    if (releaseDate) {
      return formatDateShort(releaseDate);
    } else {
      return "";
    }
  };

  return (
    <Box component="main">
      <Box mb={4} mt={1} maxWidth={320} mx="auto">
        <Autocomplete
          id="label"
          options={getDistinctLabels()}
          // freeSolo
          renderInput={params => (
            <TextField
              {...params}
              label="Label"
              variant="outlined"
              required={true}
              fullWidth={true}
              name="label"
            />
          )}
          value={filterLabel}
          onChange={(event: any, value: string | null) => {
            setFilterLabel(value);
          }}
        />
        <Box mt={1}>
          <Autocomplete
            id="genre"
            options={getGenres()}
            // freeSolo
            renderInput={params => (
              <TextField
                {...params}
                label="Genre"
                variant="outlined"
                required={true}
                fullWidth={true}
                name="genre"
              />
            )}
            value={filterGenre}
            onChange={(event: any, value: string | null) => {
              setFilterGenre(value);
            }}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterPrefilled}
              onChange={(event, checked) => {
                setFilterPrefilled(checked);
              }}
            />
          }
          label="Show only prefilled"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={hideSingles}
              onChange={(event, checked) => {
                setHideSingles(checked);
              }}
            />
          }
          label="Hide singles"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyFreshPending}
              onChange={(event, checked) => {
                setOnlyFreshPending(checked);
              }}
            />
          }
          label="Show only fresh"
        />
      </Box>
      <Box maxWidth={1200} mx="auto">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Released</TableCell>
                <TableCell>Search Term</TableCell>
                <TableCell />
                <TableCell>F/I</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {getFilteredPendingAlbums()
                .sort(
                  (a, b) =>
                    findReleaseDateForSort(a) - findReleaseDateForSort(b)
                )
                .map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {retrieveReleaseDate(row)}
                      {row.prefilledAlbum && (
                        <IconButton
                          aria-label="open"
                          onClick={() => {
                            setSelectedPrefilledAlbum(row);
                          }}
                          size="large"
                        >
                          <LaunchIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link
                        color="inherit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedCrawledAlbum({
                            ...row.crawledAlbum,
                            pendingAlbumId: row.id
                          });
                          history.push(
                            `${
                              AppRoutes.INSERT
                            }?searchTerm=${encodeURIComponent(row.searchTerm)}`
                          );
                        }}
                      >
                        {row.searchTerm}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Box>{row.crawledAlbum && row.crawledAlbum.genre}</Box>
                      <Box>{row.crawledAlbum && row.crawledAlbum.label}</Box>
                      <Box>
                        {row.crawledAlbum ? (
                          <a
                            href={row.crawledAlbum.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.crawledAlbum.single ? "single" : "album"}
                          </a>
                        ) : (
                          "no"
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        {row.foundUrls ? row.foundUrls.length : 0}/
                        {row.ignoreUrls ? row.ignoreUrls.length : 0}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {row.id && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            deletePendingAlbum(row.id);
                          }}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        open={!!selectedPendingAlbum}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
        fullScreen={isMobile}
        onKeyUp={event => {
          if (event.key === "Escape") {
            handleClose();
          }
        }}
      >
        <DialogTitle id="form-dialog-title">Insert Album</DialogTitle>
        <DialogContent>
          <DialogContentText>Add new album</DialogContentText>
          {selectedPendingAlbum && selectedPendingAlbum.prefilledAlbum && (
            <NewAlbumForm
              prefilledRelease={selectedPendingAlbum.prefilledAlbum}
              crawledAlbum={selectedPendingAlbum.crawledAlbum}
              onAlbumSubmit={() => {
                deletePendingAlbumNow(selectedPendingAlbum.id!!, handleClose);
              }}
              onCancel={handleClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export { Pending };
