import React, { useState } from "react";
import "./App.css";
import { AppContext } from "./store/context";
import AdminComponent from "./components/Admin";
import { Box, CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Header } from "./components/Header";
import { BrowserRouter as Router } from "react-router-dom";
import { CrawledAlbum } from "./store/types";
import { Footer } from "./components/Footer";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#f5b626"
    },
    success: {
      main: "#4caf50"
    },
    info: {
      main: "#2196f3"
    },
    warning: {
      main: "#ff9800"
    },
    error: {
      main: "#f44336"
    }
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
    // h1: {
    //   fontFamily: fontFamilyHeaders
    // },
    // h2: {
    //   fontFamily: fontFamilyHeaders
    // },
    // h3: {
    //   fontFamily: fontFamilyHeaders
    // },
    // h4: {
    //   fontFamily: fontFamilyHeaders
    // },
    // button: {
    //   fontFamily: fontFamilyHeaders
    // }
  }
});

function App() {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [selectedCrawledAlbum, setSelectedCrawledAlbum] = useState<
    CrawledAlbum
  >();
  const [onlyFreshPending, setOnlyFreshPending] = useState(false);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <Box>
            <AppContext.Provider
              value={{
                loggedIn,
                setLoggedIn,
                selectedCrawledAlbum,
                setSelectedCrawledAlbum,
                onlyFreshPending,
                setOnlyFreshPending
              }}
            >
              <Box pb={5}>
                <Header />
                <AdminComponent />
                <Footer />
              </Box>
            </AppContext.Provider>
          </Box>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
